export enum RoleName {
  owner = 'owner',
  admin = 'admin',
  initiator = 'initiator',
  bookkeeper = 'bookkeeper',
}

export const roleLabels: Record<RoleName, string> = {
  [RoleName.owner]: 'Owner',
  [RoleName.admin]: 'Approver',
  [RoleName.initiator]: 'Initiator',
  [RoleName.bookkeeper]: 'Bookkeeper',
};
