// Given a cookie key `name`, returns the value of
// the cookie or `null`, if the key is not found.
export function getCookie(name: string): string {
  const nameLenPlus = name.length + 1;
  return (
    document.cookie
      .split(';')
      .map((c) => c.trim())
      .filter((cookie) => {
        return cookie.substring(0, nameLenPlus) === `${name}=`;
      })
      .map((cookie) => {
        return decodeURIComponent(cookie.substring(nameLenPlus));
      })[0] || ''
  );
}

export function setHeaders(headers: Headers) {
  const cookie = getCookie('x-series-csrf-token');
  headers.set('x-series-csrf-token', cookie);

  return headers;
}

export const BASE_URL = `${process.env.NEXT_PUBLIC_GATEWAY_BASE_URL}/nucleus`;
