import type { AppProps } from 'next/app';
import { ThemeProvider } from 'styled-components';

import { theme, GlobalStyle, AuthProvider } from '@seriesfi/ui';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import { Provider } from 'react-redux';
import { store } from '@/api/store/store';
import Seo from '@/components/common/Seo';

export default function App({ Component, pageProps }: AppProps) {
  const clientSideID = process.env
    .NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_SIDE_ID as string;

  const ldClientConfig = {
    clientSideID,
  };

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <LDProvider {...ldClientConfig}>
          <GlobalStyle />
          <AuthProvider>
            <Seo />
            <Component {...pageProps} />
          </AuthProvider>
        </LDProvider>
      </Provider>
    </ThemeProvider>
  );
}
